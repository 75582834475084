<style scoped>
</style>

<template>
    <ButtonTopbar buttonText="Créer une nature d'article" @onTopbarButtonClicked="displayCreateArticleKindModal">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
    </ButtonTopbar>
    <Aggrid
        apiRoute='articleKind/list'
        :apiParams="filter"
        :columnDefs="columnDefs" 
        :frameworkComponents="frameworkComponents"
        :context="context"
        aggridHeightSubstract="100px"
    >
        <template v-slot:filter>
            <div>
                <BaseInput v-model="search" label="Rechercher une nature d'article" type="text" name="search" @onChange="onSearchInputChange">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </template>
                </BaseInput>
            </div>
        </template>
    </Aggrid>
    <Modal v-show="displayModalCreateOrEditArticleKind === true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', createOrEditMode === 'create' ? 'plus' : 'edit']" />
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrEditMode === 'create'">CRÉER UNE NATURE D'ARTICLE</div>
            <div v-show="createOrEditMode === 'edit'">MODIFIER UNE NATURE D'ARTICLE</div>
        </template>
        <template v-slot:modalContent>
            <form class="form-column">
                <div>
                    <BaseInput 
                        v-model="articleKind.label"
                        label="Libellé"
                        type="text"
                        tabIndex="1"
                        name="label"
                        validator="text"
                        :error="formErrors?.label?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError" 
                    />
                    <BaseInput 
                        v-model="articleKind.saleAccount"
                        label="Compte de vente"
                        type="text"
                        tabIndex="3"
                        name="saleAccount"
                        validator="text"
                        :error="formErrors?.saleAccount?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                    <RadioButtonContainer label="Afficher sur le devis">
                        <BaseRadioButton v-model="articleKind.isOnQuotation" label="Oui" name="isOnQuotation" value="true"></BaseRadioButton>
                        <BaseRadioButton v-model="articleKind.isOnQuotation" label="Non" name="isOnQuotation" value="false"></BaseRadioButton>
                    </RadioButtonContainer>
                </div>
                <div>
                    <BaseInput 
                        v-model="articleKind.code"
                        label="Code"
                        type="text"
                        tabIndex="2"
                        name="code"
                        validator="text"
                        :error="formErrors?.code?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError" 
                        :disabled="createOrEditMode !== 'create'"
                    />
                    <BaseInput 
                        v-model="articleKind.buyAccount"
                        label="Compte d'achat"
                        type="text"
                        tabIndex="4"
                        name="buyAccount"
                        validator="text"
                        :error="formErrors?.buyAccount?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                    <RadioButtonContainer label="Sortir de stock">
                        <BaseRadioButton v-model="articleKind.handleOutOfStock" label="Oui" name="handleOutOfStock" value="true"></BaseRadioButton>
                        <BaseRadioButton v-model="articleKind.handleOutOfStock" label="Non" name="handleOutOfStock" value="false"></BaseRadioButton>
                    </RadioButtonContainer>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Dialog ref="dialog" />
</template>

<script>
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar'
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import BaseSelect from '../../components/Base/BaseSelect'
    import BaseRadioButton from '../../components/Base/BaseRadioButton'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import Modal from '../../components/App/Modal'
    import Dialog from '../../components/App/Dialog'
    import RadioButtonContainer from '../../components/App/RadioButtonContainer'
    import ListWithDelete from '../../components/App/ListWithDelete'
    import ListTextWithIconRenderer from '../../components/Aggrid/ListTextWithIconRenderer'
    import ListArticleKindsActionButtonsRenderer from './ListArticleKindsActionButtonsRenderer'
    import axios from 'axios'
import changeTypeEnum from '../../enums/changeTypeEnum'

    export default {
        name: 'ArticleKinds',
        components: {
            ButtonTopbar,
            BaseButton,
            BaseInput,
            BaseSelect,
            BaseRadioButton,
            RadioButtonContainer,
            ListWithDelete,
            ListTextWithIconRenderer,
            ListArticleKindsActionButtonsRenderer,
            Aggrid,
            Modal,
            Dialog
        },
        data() {
            return {
                columnDefs: [
                    { field: 'label', headerName : `Libellé`, flex: 1 },
                    { field: 'code', headerName : `Code`, flex: 1 },
                    { field: 'buyAccount', headerName : `Compte d'achat`, flex: 1 },
                    { field: 'saleAccount', headerName : `Compte de vente`, flex: 1 },
                    { headerName : 'Aff. sur devis', flex: 1, cellRenderer: 'ListTextWithIconRenderer', cellRendererParams: { 
                        field: 'isOnQuotation',
                        mode: 'boolean'
                    }, filterParams: {
                        filterOptions: ['equals']
                    }},
                    { headerName : 'Sortie stock', flex: 1, cellRenderer: 'ListTextWithIconRenderer', cellRendererParams: { 
                        field: 'handleOutOfStock',
                        mode: 'boolean'
                    }, filterParams: {
                        filterOptions: ['equals']
                    }},
                    { headerName : '', width: 130, cellRenderer: 'ListArticleKindsActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListTextWithIconRenderer: ListTextWithIconRenderer,
                    ListArticleKindsActionButtonsRenderer: ListArticleKindsActionButtonsRenderer
                },
                context: null,
                displayModalCreateOrEditArticleKind: false,
                createOrEditMode: null,
                search: '',
                searchTimeout: null,
                articleKind: null,
                filter: {
                    search: ''
                },
                displayError: false,
                formErrors: {}
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetArticleKind();
        },
        methods: {
            closeModal() {
                this.resetArticleKind();
                this.displayModalCreateOrEditArticleKind = false;
                this.displayError = false;
            },
            submitModal() {
                if (this.createOrEditMode === 'create') {
                    this.createArticleKind();
                } else if (this.createOrEditMode === 'edit') {
                    this.editArticleKind();
                }
            },
            displayCreateArticleKindModal() {
                this.createOrEditMode = 'create';
                this.resetArticleKind();
                this.displayModalCreateOrEditArticleKind = true;
            },
            displayEditArticleKindModal(articleKind) {
                this.createOrEditMode = 'edit';
                this.articleKind = JSON.parse(JSON.stringify(articleKind));
                this.articleKind.isOnQuotation = this.articleKind.isOnQuotation ? 'true' : 'false';
                this.articleKind.handleOutOfStock = this.articleKind.handleOutOfStock ? 'true' : 'false';
                this.displayModalCreateOrEditArticleKind = true;
            },
            resetArticleKind() {
                this.articleKind = {
                    code: null,
                    label: null,
                    buyAccount: null,
                    saleAccount: null,
                    isOnQuotation: 'true',
                    handleOutOfStock: 'true'
                };
            },
            formateArticleKind(articleKind) {
                articleKind = JSON.parse(JSON.stringify(articleKind));
                articleKind.isOnQuotation = this.articleKind.isOnQuotation === 'true';
                articleKind.handleOutOfStock = this.articleKind.handleOutOfStock === 'true';
                return articleKind;
            },
            createArticleKind() {
                if (!this.checkForm()) return;

                axios
                .post('articleKind/create', this.formateArticleKind(this.articleKind), { 
                    toastSuccessMessage: `Nature d'article créée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModal();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            editArticleKind() {
                if (!this.checkForm()) return;

                axios
                .put('articleKind/update', this.formateArticleKind(this.articleKind), { 
                    toastSuccessMessage: `Modification effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                   this.closeModal();
                   this.emitter.emit('ag-grid-reload')
                })
                .catch(() => {});
            },
            onFormInputChange(input) {
                if (input.error?.message !== null) {
                    this.formErrors[input.name] = input;
                } else {
                    delete this.formErrors[input.name];
                }

                if (input.changeType === changeTypeEnum.USER) {
                    switch (input.name) {
                        case 'label':
                            this.articleKind.code = input.value.substr(0, 3).toUpperCase();
                        break;
                    }
                }
            },
            checkForm() {
                this.displayError = true;

                return Object.keys(this.formErrors).length === 0;
            },
            onSearchInputChange(input) {
                 if(this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }
                
                this.searchTimeout = setTimeout(() => {
                    this.filter.search = input.value
                },250)
            }
        }
    }
</script>